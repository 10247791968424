import { CleaningImage } from "../../assets";
import "./styles.css";

const Cleaning = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="image">
          <img src={CleaningImage} alt="Card image" />
        </div>
        <div className="content">
          <h3
            style={{
              textTransform: "uppercase",
              color: "black",
              fontWeight: 800,
            }}
          >
            Entretien
          </h3>
          <ul>
            <li>Carénage</li>
            <li>Passe coques</li>
            <li>Lavage pont et coques</li>
            <li>Changement de hublot /plexi</li>
            <li>Divers</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cleaning;
