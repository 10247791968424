import React from "react";
import { BoussoleImage } from "../assets";
import Accastillage from "./component/accastillage";
import Agencement from "./component/agencement";
import Cleaning from "./component/cleaning";
import Greement from "./component/greement";
import Seamanship from "./component/seamanship";
import "./styles.css";

const Services = () => {
  return (
    <div className="services-container">
      <div className="row">
        <Seamanship />
        <Greement />
      </div>
      <img src={BoussoleImage} alt="Center Image" className="center-image" />
      <div className="row">
        <Cleaning />
        <Agencement />
      </div>
      <div className="row">
        <Accastillage />
      </div>
    </div>
  );
};

export default Services;
