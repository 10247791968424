import { MatelotageImage } from "../../assets";
import "./styles.css";

const Seamanship = () => {
  return (
    <div className="container">
      <div className="card">
        <div
          className="image"
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <img src={MatelotageImage} alt="Card image" />
        </div>
        <div className="content">
          <h3
            style={{
              textTransform: "uppercase",
              color: "black",
              fontWeight: 800,
            }}
          >
            Matelotage
          </h3>
          <ul>
            <li>Epissure sur drisse/cablot</li>
            <li>Amarres</li>
            <li>Epissures divers sur accastillage ou équipements</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Seamanship;
