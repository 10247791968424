import { GreementImage } from "../../assets";
import "./styles.css";

const Greement = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="image">
          <img src={GreementImage} alt="Card image" />
        </div>
        <div className="content">
          <h3
            style={{
              textTransform: "uppercase",
              color: "black",
              fontWeight: 800,
            }}
          >
            Gréement
          </h3>
          <ul>
            <li>Pose / changement enrouleur</li>
            <li>Dématage / matage</li>
            <li>Entretien gréement courant</li>
            <li>Entretien gréement dormant</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Greement;
