import React from "react";
import { LogoIntradosSailing } from "../assets";
import videoSource from "../assets/background-video.mp4";
import "./styles.css";

function Header() {
  return (
    <div className="header-container">
      <div className="header-logo">
        <img
          src={LogoIntradosSailing}
          alt="IntradosSailingLogo"
          className="header-logo-img"
        />
      </div>

      <div className="header-video-container">
        <video autoPlay loop muted className="header-video">
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h1 className="header-title">INTRADOS SAILING</h1>
      </div>
    </div>
  );
}

export default Header;
