import { AgencementImage } from "../../assets";
import "./styles.css";

const Agencement = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="image">
          <img src={AgencementImage} alt="Card image" />
        </div>
        <div className="content">
          <h3
            style={{
              textTransform: "uppercase",
              color: "black",
              fontWeight: 800,
            }}
          >
            Agencement
          </h3>
          <ul>
            <li>Menuiserie</li>
            <li>Vaigrage / fargue</li>
            <li>Pose et modification de meuble</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Agencement;
