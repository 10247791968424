import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LocationPoint } from "../assets";
import L from "leaflet";

const MapWithDefaultPosition = () => {
  const defaultPosition = [46.194070280847114, -1.1825359691383994];

  const customMarkerIcon = new L.Icon({
    iconUrl: LocationPoint,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  return (
    <div
      style={{
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MapContainer
        center={defaultPosition}
        zoom={13}
        style={{ height: "200px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={defaultPosition} icon={customMarkerIcon}>
          <Popup>
            <a
              href="https://www.flaticon.com/fr/icones-gratuites/marque-de-localisation"
              title="marque de localisation icônes"
            >
              Marque de localisation icônes créées par Creartive - Flaticon
            </a>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapWithDefaultPosition;
