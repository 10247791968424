import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* <p>
          <a href="/terms-of-service">Termes et conditions</a> |{" "}
          <a href="/privacy-policy">Politique de confidentialité</a>
        </p> */}
        <p>© 2024 Intrados Sailing. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
