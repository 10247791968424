import "./App.css";
import Footer from "./footer";
import ContactForm from "./formContact";
import Header from "./header";
import MapWithDefaultPosition from "./maps";
import Services from "./services";

function App() {
  return (
    <div className="App">
      <Header />
      <Services />
      <MapWithDefaultPosition />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
