import "./styles.css";

const ContactForm = () => {
  return (
    <div style={{ backgroundColor: "#F0F0F0", margin: 20, borderRadius: 10 }}>
      <div className="contact-info">
        <div className="contact-item">
          <h4>Email:</h4>
          <p>intrados.sailing@gmail.com</p>
        </div>
        <div className="contact-item">
          <h4>Téléphone:</h4>
          <p>+33 635231245</p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
